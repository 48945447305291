@import "../setting/import";

.page-error {

	.error-image {
    max-width: 60%;
    margin: sp-vw(120) auto sp-vw(30);
    transform: translateX(sp-vw(30));

    @include wide {
      max-width: 307px;
      margin: 71px auto 32px;
      transform: translateX(20px);
    }
  }

  .error-info {
    text-align: center;

    @include wide {
      margin-bottom: 76px;
    }
  }

  .simple-section__text {
    line-height: 2;
    letter-spacing: .08em;


    @include sp {
      font-size: 1.4rem;
    }
  }

  .simple-section__button {
    margin-top: sp-vw(40);

    @include wide {
      margin-top: 36px;
    }


    .global-button {
      margin: 0 auto;
    }
  }

}// page end