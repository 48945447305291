.page-error .error-image {
  max-width: 60%;
  margin: 16vw auto 4vw;
  transform: translateX(4vw);
}

@media (min-width: 768px) {
  .page-error .error-image {
    max-width: 307px;
    margin: 71px auto 32px;
    transform: translateX(20px);
  }
}

.page-error .error-info {
  text-align: center;
}

@media (min-width: 768px) {
  .page-error .error-info {
    margin-bottom: 76px;
  }
}

.page-error .simple-section__text {
  line-height: 2;
  letter-spacing: .08em;
}

@media (max-width: 767px) {
  .page-error .simple-section__text {
    font-size: 1.4rem;
  }
}

.page-error .simple-section__button {
  margin-top: 5.33333vw;
}

@media (min-width: 768px) {
  .page-error .simple-section__button {
    margin-top: 36px;
  }
}

.page-error .simple-section__button .global-button {
  margin: 0 auto;
}
